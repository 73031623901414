<template>
    <div class="w-full flex justify-between bg-white text-gray-900 px-5 md:px-9">
      <a href="#" class="flex text-lg font-medium my-6">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2 text-yellow-400 h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
          <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
          />
        </svg>
        Smart lottery
      </a>

      <div class="relative hidden md:flex flex-col items-center">
        <button @click="openChangeContact"
          class="relative z-20 flex justify-center pt-1 w-20 h-10 border-l-8 border-r-8 border-b-8 border-yellow-200
          rounded-b-full bg-yellow-400 text-gray-800 hover:text-yellow-400 hover:bg-gray-800 hover:border-gray-200
          animate-pulse"
          >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-4" viewBox="0 0 20 11" fill="none">
          <path
              d="M1.86998 0.408361L0.0999756 1.8917L9.99998 10.1334L19.9 1.88336L18.13 0.408361L9.99998 7.18336L1.86998 0.408361Z"
              fill="currentColor"
          />
        </svg>
        </button>
        <p class="text-gray-900 font-bold text-sm text-center">Try frontend with your deployed contract ID</p>
        <change-contract
          v-show="openContract"
          @openChangeContact="openChangeContact"
        />
      </div>

      <div v-if="accountId" class=" hidden md:flex">
        <span class="flex bg-yellow-400 text-base font-medium rounded-md py-2 px-5 my-5 hover:bg-yellow-300">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          {{accountId}}
        </span>

        <button
            @click="signOut"
            class="flex bg-yellow-400 text-base font-medium rounded-md py-2 px-5 my-5 ml-7 hover:bg-yellow-300"
        >
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6 text-gray-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
          Logout
        </button>
      </div>
      <div v-else class="hidden md:flex">
        <button
            @click="signIn"
            class="flex bg-yellow-400 text-base font-medium rounded-md py-2 px-5 my-5 ml-7 hover:bg-yellow-300"
        >
          Login
        </button>
      </div>

      <button @click="setOpenMobileMenu" class="sm:block md:hidden ml-auto text-gray-900">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"/>
        </svg>
      </button>

      <MobileMenu v-if="openMobileMenu" @setOpenMobileMenu="setOpenMobileMenu"/>
    </div>
</template>

<script>
  import ChangeContract from './ChangeContract'
  import MobileMenu from '../MobileMenu'

  export default {
    data(){
      return {
        openContract: false,
        openMobileMenu: false,
      }
    },
    props: {
      accountId: {
        type: String,
        default: '',
      },
      signIn: {
        type: Function,
        default: () => {},
      },
      signOut: {
        type: Function,
        default: () => {},
      }
    },
    components: {
      ChangeContract,
      MobileMenu,
    },
    methods: {
      openChangeContact(){
        this.openContract = !this.openContract;
      },
      setOpenMobileMenu(){
        this.openMobileMenu = !this.openMobileMenu;
      },
    },
  }
</script>


