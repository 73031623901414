<template>
  <footer class="w-full block mt-auto bg-gray-900 px-9 py-6 z-50">
    <h2 class="text-white text-2xl font-medium">Smart Lottery</h2>
    <ul class="flex ml-4 mt-3">
      <li>
        <a href="#" class="mr-3 text-lg text-gray-300 hover:underline">
          F.A.Q.
        </a>
      </li>
      <li>
        <a href="#" class="mr-3 text-lg text-gray-300 hover:underline">
          Contacts
        </a>
      </li>
      <li>
        <a href="#" class="mr-3 text-lg text-gray-300 hover:underline">
          Rules
        </a>
      </li>
    </ul>
    <p class="text-gray-700 text-right">
      © 2021 All rights reserved. Develop by
      <a href="https://t.me/NazarNyzhnyk" class="text-gray-400 hover:underline font-medium">
        Nazar Nyzhnyk
      </a>
    </p>
  </footer>
</template>