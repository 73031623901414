import { ref } from 'vue';

const alertMessage = ref('');
const showAlert = ref(false);

export const useAlertMessage = () => {
  const setAlertMessage = (message) => {
    alertMessage.value = message
  };
  const setShowAlert = (data = false) => {
    showAlert.value = data
  }

  return {
    alertMessage,
    setShowAlert,
    setAlertMessage,
    showAlert,
  }
};