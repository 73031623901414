<template>
  <div  v-if="showAlert" class="absolute w-full flex justify-center pt-9">
    <div class="bg-white w-500 inline-block rounded-md px-4 py-5 px-9 shadow-2xl">

      <svg @click="handleShowAlert" v-if="alertType === 'success'" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer h-8 w-8 mx-auto text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <svg @click="handleShowAlert" v-else-if="alertType === 'danger'" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer h-8 w-8 mx-auto text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      <svg @click="handleShowAlert" v-else xmlns="http://www.w3.org/2000/svg" class="cursor-pointer h-8 w-8 mx-auto text-yellow-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
      </svg>

      <p class="text-center text-4xl font-medium">{{alertType === 'danger' ? 'Error' : 'Successful'}}</p>
      <p class="text-center mt-4 font-medium text-gray-500">{{alertMessage}}</p>

      <button v-if="alertType === 'success'" style="box-shadow: 0px 4px 15px rgba(39, 174, 96, 0.75);" class="block bg-green-400 hover:bg-green-300 rounded-md text-white font-medium mt-5 mx-auto py-2 px-9">Continue</button>
      <button v-else-if="alertType === 'danger'" style="box-shadow: 0px 4px 15px rgba(235, 87, 87, 0.75);" class="block bg-red-400 hover:bg-red-300 rounded-md text-white font-medium mt-5 mx-auto py-2 px-9">Continue</button>
      <button v-else style="box-shadow: 0px 4px 15px rgba(251, 191, 36, 0.75);" class="block bg-yellow-400 hover:bg-yellow-300 rounded-md text-white font-medium mt-5 mx-auto py-2 px-9">Continue</button>

    </div>
  </div>
</template>

<script>
import {useAlertMessage} from "../composables/useAlertMessage";

  export default {
    props: {
      alertType:{
        type: String
      },
    },
    methods: {
      handleShowAlert(){
        this.setShowAlert(false)
      }
    },
    setup(){
      const { alertMessage, showAlert , setShowAlert} = useAlertMessage();
      return {alertMessage, showAlert, setShowAlert}
    }
  }
</script>

<style>

</style>
