<template>
  <div class="w-full bg-white rounded-md px-5 py-6 shadow-2xl md:ml-0 mt-9 lg:mt-0 lg:ml-9 flex flex-col justify-between">
    <div class="block md:flex">
      <div class="text-left">
        <p class="text-sm font-medium text-gray-400">Place your bet</p>
        <p class="text-gray-900 font-bold text-2xl my-2">First time is free to play
          <br class="hidden md:block"> and you may win!
        </p>
        <p class="text-gray-600 font-normal mr-6">
          If you've already played once then any other play
          costs you a fee. This fee is calculated as 1 NEAR X
          the square of the total number of unique players
        </p>
      </div>

      <div v-if="has_played" class="ml-auto mt-10">
        <p class="text-gray-500 flex font-bold ">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
            />
          </svg>
          Fee
        </p>
        <p class="text-gray-900 text-xl font-bold mt-2 ml-2 whitespace-nowrap">{{fee}}</p>
      </div>
    </div>

    <div class="w-full flex justify-center">
      <button
          @click="handlePlay"
          style="box-shadow: 0 4px 15px rgba(255, 206, 34, 0.75) "
          class="bg-yellow-400 py-3 w-full md:w-1/2 rounded-md mt-10 font-medium shadow-2xl text-gray-900 hover:bg-yellow-300"
      >
      Play
      </button>
    </div>
    <div v-if="accountId && owner === accountId" class="w-full flex justify-center">
      <button
        @click="reset"
        style="box-shadow: 0 4px 15px rgba(255, 206, 34, 0.75)"
        class="bg-yellow-400 py-3 w-full md:w-1/2 rounded-md mt-10 font-medium shadow-2xl text-gray-900 hover:bg-yellow-300"
      >
      Reset
      </button>
    </div>

  </div>
</template>

<script>
  export default {
    props:{
      has_played:{
        type: Boolean
      },
      accountId:{
        type: String
      },
      owner:{
        type: String
      },
      fee:{
        type: String
      },
      signIn:{
        type: Function,
        default: () => {}
      },
      reset:{
        type: Function,
        default: () => {}
      },
      play:{
        type: Function,
        default: () => {}
      },
    },
    methods: {
      handlePlay() {
        if (this.accountId) {
          this.play();
        } else {
          try {
            this.signIn();
          } catch (error) {
            // setApiError(error);
          }
        }
      },
    }
  }
</script>
