<template>
  <div class="flex flex-col space-between h-screen">
    <header>
      <navigation
          :accountId="accountId"
          :signIn="signIn"
          :signOut="signOut"
      />
    </header>

    <main class="pb-10">
      <Alert
        :alert-type="'danger'"
      />
      <decoration-dots />
      <decoration-lines />
      <decoration-circle-sm />
      <decoration-circle-md />
      <decoration-circleLg />

      <div class="w-full mt-9 px-5 md:px-9">
        <div class="w-full xl:w-1/2 mx-auto bg-white rounded-md shadow-2xl py-6">
          <h2 class="text-center font-medium text-gray-800 text-3xl mx-4">
            This page was created not for the game just for testing
          </h2>
        </div>
      </div>

      <div class="w-full sm:block md:block lg:flex xl:flex px-5 mt-9 md:px-9">
        <InfoLottery
            :chance="chance"
            :owner="lottery.owner.value"
            :winner="lottery.winner.value"
            :fee="lottery.fee.value"
            :pot="lottery.pot.value"
            :fee_strategy="lottery.feeStrategy.value"
            :date="lottery.date.value"
            :reset="lottery.reset"
        />
        <PlayComponent
            :accountId="accountId"
            :owner="lottery.owner.value"
            :signIn="signIn"
            :fee="lottery.fee.value"
            :has_played="lottery.hasPlayed.value"
            :play="lottery.play"
            :reset="lottery.reset"
            setApiError="setApiError"
        />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { useSign } from '../composables/useSign';
import { useLottery } from '../composables/useLottery';
import Alert from '../components/Alert'
import DecorationDots from '../components/decoration/DecorationLines'
import DecorationLines from '../components/decoration/DecorationDots'
import DecorationCircleSm from '../components/decoration/DecorationCircleSm'
import DecorationCircleMd from '../components/decoration/DecorationCircleMd'
import DecorationCircleLg from '../components/decoration/DecorationCircleLg'
import Navigation from '../components/Navigation'
import InfoLottery from '../components/InfoLottery'
import PlayComponent from '../components/PlayComponent'
import Footer from '../components/Footer'

export default {
  components: {
    Alert,
    DecorationDots,
    DecorationLines,
    DecorationCircleSm,
    DecorationCircleMd,
    DecorationCircleLg,
    Navigation,
    InfoLottery,
    PlayComponent,
    Footer,
  },
  computed: {
    chance(){
      return this.lottery.lotteryExplanation.value === '' ? this.lottery.lotteryExplanation.value : this.lottery.lotteryExplanation.value.match(/(\d+)/)[0] + '%';
    }
  },

  setup(){
    const { accountId, signIn, signOut } = useSign();
    const { ...lottery } = useLottery();

    return { accountId, signIn, signOut, lottery}
  },

}
</script>
