<template>
  <div class="absolute top-0 left-0 w-full">
    <div class="w-full px-6 py-9 bg-white">
      <!-- Open burger menu header-->
      <div class="flex justify-between">
        <p class="font-medium flex text-lg">
          <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 text-yellow-400 h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"></path>
          </svg>
          Smart Lottery
        </p>
        <!-- Close btn -->
        <a @click="handleOpenMobileMenu" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-900 ml-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </a>
      </div>
      <!-- Account btn -->
      <a href="#" class="block flex bg-yellow-400 w-full text-base font-medium rounded-md py-2 px-5 my-5 mt-9 hover:bg-yellow-300">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
        </svg>
        Nazar Nyzhnyk
      </a>
      <!-- Balance -->
      <a href="#" class="block flex bg-yellow-400 w-full text-base font-medium rounded-md py-2 px-5 my-5 hover:bg-yellow-300">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"></path>
        </svg>
        51651
      </a>
      <!-- Logout btn -->
      <a href="#" class="block flex bg-yellow-400 w-full text-base font-medium rounded-md py-2 px-5 my-5 hover:bg-yellow-300">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-6 w-6 text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
        </svg>
        Logout
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {

      }
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      setOpenMobileMenu: {
        type: Function,
        default: () => {}
      }
    },
    methods: {
      handleOpenMobileMenu(){
        this.$emit('setOpenMobileMenu')
      }

    }
  }
</script>