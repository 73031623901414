import { ref, onMounted } from 'vue';
import { wallet } from '../services/near';
import { useContract } from './useContract';

export const useSign = () => {
  const { contractId } = useContract();
  const accountId = ref('');
  const error = ref('');
  const setAccountId = (data) => {
    accountId.value = data
  };
  const setError = (data) => {
    error.value = data
  };

  const getAccountId = async () => {
    try {
      setAccountId(await wallet.getAccountId());
    } catch (error) {
      setError(error);
    }
  };

  onMounted(() => {
    getAccountId()
  })

  const handleSignIn = () => {
    console.log('handleSignIn - contractId', contractId)
    wallet.requestSignIn({
      contractId: contractId.value,
      methodNames: [], // add methods names to restrict access
    });
  };

  const handleSignOut = () => {
    console.log('handleSignOut')
    wallet.signOut();
    getAccountId();
  };

  return {
    accountId,
    signIn: handleSignIn,
    signOut: handleSignOut,
    error,
  };
};
