import { ref, watch } from 'vue';
import {useLottery} from './useLottery';

const data = ref(localStorage.getItem('CONTRACT_ID'));

export const useContract = (setApiError) => {
  const  {updateValues} = useLottery()
  const defaultContractId = process.env.VUE_APP_CONTRACT_ID;
  const contractId = localStorage.getItem('CONTRACT_ID');

  const setData = (contractId) => { data.value = contractId };

  if(!contractId){
    localStorage.setItem('CONTRACT_ID', defaultContractId);
    setData(defaultContractId)
  }

  const setContractId = (contractId) => {
    localStorage.setItem('CONTRACT_ID', contractId);
    setData(contractId);
    setApiError ? setApiError('') : null;
  };

  watch(data, () => {
    updateValues()
  }, {deep: true})

  return {
    contractId: data,
    setContractId
  }
};