import {wallet} from '../services/near';
import {onMounted, ref } from 'vue';
import { useAlertMessage } from './useAlertMessage';
import {
  getOwner,
  getWinner,
  getPot,
  getFee,
  getFeeStrategy,
  getHasPlayed,
  getLastPlayed,
  getActive,
  getExplainFees,
  getExplainLottery,
  play,
  reset,
} from '../services/near';

const FeeStrategies = ['Free', 'Constant', 'Linear', 'Exponential'];

const accountId = wallet.getAccountId();
const owner = ref('');
const winner = ref('');
const pot = ref('');
const fee = ref('');
const feeStrategy = ref('');
const hasPlayed = ref('');
const lastPlayed = ref('');
const active = ref('');
const feesExplanation = ref('');
const lotteryExplanation = ref('');
const date = ref('');


export const useLottery = () => {
  const { setAlertMessage, setShowAlert } = useAlertMessage();

  const setOwner = (data => owner.value = data)
  const setWinner = (data => winner.value = data)
  const setPot = (data => pot.value = data)
  const setFee = (data => fee.value = data)
  const setFeeStrategy = (data => feeStrategy.value = data)
  const setHasPlayed = (data => hasPlayed.value = data)
  const setLastPlayed = (data => lastPlayed.value = data)
  const setActive = (data => active.value = data)
  const setFeesExplanation = (data => feesExplanation.value = data)
  const setLotteryExplanation = (data => lotteryExplanation.value = data)
  const setDate = (data => date.value = data)

  const updateValues = async () => {
    try {
      setOwner(await getOwner() || '');
      setWinner(await getWinner());
      setPot(await getPot());
      setFee(await getFee());
      setFeeStrategy(FeeStrategies[await getFeeStrategy()]);
      setHasPlayed(await getHasPlayed(accountId));
      setLastPlayed(await getLastPlayed());
      setActive(await getActive());
      setFeesExplanation(await getExplainFees());
      setLotteryExplanation(await getExplainLottery());
      setDate('03/07/21 11:00 Am');
    } catch (err) {
      setAlertMessage(err.message);
      setShowAlert(true);
      setOwner( '');
      setWinner('');
      setPot('');
      setFee('');
      setFeeStrategy('');
      setHasPlayed('');
      setLastPlayed('');
      setActive('');
      setFeesExplanation('');
      setLotteryExplanation('');
      setDate('');
    }
  };

  onMounted(() => {
    try {
      updateValues();
    } catch (err) {
      setAlertMessage(err.message);
      console.log(2222222222)
    }
  })

  const handlePlay = async () => {
    await play(fee.value, hasPlayed.value);
    setFee(await getFee());
    setHasPlayed(await getHasPlayed(accountId));
    setWinner(await getWinner());
  };

  const handleReset = async () => {
    reset();
  };

  return {
    owner,
    winner,
    pot,
    fee,
    feeStrategy,
    hasPlayed,
    lastPlayed,
    active,
    feesExplanation,
    lotteryExplanation,
    date,
    play: handlePlay,
    reset: handleReset,
    updateValues,
  };
};
